<template>
  <div style="width: 100%;" id="tishikuang">
<!--    {{secondPower}}-->
    <div :id="id" :style="{width: width, height: height}"></div>
    <div style="display: flex;align-items: center;justify-content: space-between;padding: 15px 15px 0 45px;">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(0,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(0,0)" style="margin-top: 10px" />
      </div>
      <div style="display: flex;flex-direction: column;align-items: center">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(1,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(1,0)"  style="margin-top: 10px" />
      </div>
      <div style="display: flex;flex-direction: column;align-items: center">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(2,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(2,0)" style="margin-top: 10px" />
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(3,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(3,0)" style="margin-top: 10px" />
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(4,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(4,0)" style="margin-top: 10px" />
      </div>

      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(5,1)" />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(5,0)"  style="margin-top: 10px" />
      </div>

      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(6,1)"  />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(6,0)"  style="margin-top: 10px" />
      </div>

      <div style="display: flex;flex-direction: column;align-items: center;">
        <a-icon type="plus-square" @click.stop="changeTongjiTu(7,1)"  />
        <a-icon type="minus-square" @click.stop="changeTongjiTu(7,0)" style="margin-top: 10px" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "myCustomEcharts",
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    option: {
      type: Object,
      required: true
    },
    minPower: {
      type:Number
    },
    maxPower: {
      type:Number
    },
    maxOutputVoltage: {
      type:Number
    },
    secondPower : {
      type: Number
    },
    currentVoltage: {
      type: Array
    },
    resistanceValue: {
      type:Number
    },
    powerData:{
      type:Object
    }
  },
  data() {
    return {
      chartInstance: null
    };
  },
  mounted() {
    // if (this.width) {
    //   (document.querySelector('.chart')).style.width = this.width + 'px';
    // }
    this.chartInstance = echarts.init(document.getElementById(this.id));
    this.chartInstance.setOption(this.option);
    let that = this;
    this.chartInstance.on('mousedown', function (params) {
      console.log('params')
      console.log(params.seriesIndex)
      if (params.componentType === 'series') {
        var seriesIndex = params.seriesIndex;
        var pointIndex = params.dataIndex;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        //鼠标move移动时修改折线图y轴
        function onMouseMove(event) {
          var pointInPixel = [event.offsetX, event.offsetY];

          //把offsetXY像素坐标转换为grid坐标，[0是x轴下标，1是y轴的值]
          var pointInGrid = that.chartInstance.convertFromPixel('grid', pointInPixel);

          var data = that.option.series[seriesIndex].data;

          //y轴保留整数

          if((parseFloat(pointInGrid[1]).toFixed(1) <= that.option.yAxis.max) && (parseFloat(pointInGrid[1]).toFixed(1) >= that.option.yAxis.min)) {
            data[pointIndex] = parseFloat(pointInGrid[1]).toFixed(1);
            that.chartInstance.setOption(that.option);

            that.changVoltage(pointIndex,parseFloat(pointInGrid[1]).toFixed(1))
          }


          // this.option.title.text = params.seriesName + '，' + params.name + '，' + '新值：' + data[pointIndex]

        }

        //鼠标up放开时删除鼠标move移动事件
        function onMouseUp() {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        }
      }
    });
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
  methods: {
    changVoltage(index,power) {
      // console.log(index);
      // console.log(power);
    let dianya =  power * this.powerData.resistanceValue
      // console.log(dianya);
     let voltageValue =  Math.sqrt(dianya).toFixed(1);

      // console.log(voltageValue);
      // return;
      this.$emit('changeCurrentData',{
        index:index,
        value:voltageValue
      })
    },
    changePeizhiShuju() {
      if(this.chartInstance) {
        this.chartInstance.setOption(this.option);

      } else {
        this.chartInstance = echarts.init(document.getElementById(this.id));
        this.chartInstance.setOption(this.option);
      }
    },
    changeTongjiTu(index,option) {
      if(this.chartInstance.getOption()) {
        console.log('-----')
        let chartData = this.chartInstance.getOption().series[0].data;
        // if(parseFloat(this.secondPower) > 0) {
          console.log('+++++')

          if(option == 1) {
            //+
            let addVoltage = this.currentVoltage[index] + 0.1; //等于多少电压
            if(addVoltage < this.powerData.maxOutputVoltage) {
              let zuihoudeGonlv =  (addVoltage * addVoltage / this.powerData.resistanceValue);
              let baoliuyiweizhi = parseFloat(zuihoudeGonlv.toFixed(1));
              console.log(baoliuyiweizhi)
              console.log(this.powerData.maxPower)
              if(baoliuyiweizhi < this.powerData.maxPower) {
                //修改成功
                console.log('修改成功');
                this.$emit('changeCurrentData',{
                  index:index,
                  value:addVoltage
                })
                chartData[index] = baoliuyiweizhi;
                this.chartInstance.setOption({
                    series:[{
                      data: chartData,
                    }]
                  })

                this.$emit('changeCharts',chartData)
              }
            }
          } else {
            //-
            let addVoltage = this.currentVoltage[index] - 0.1; //等于多少电压
            if(addVoltage >= 0.4) {
              let zuihoudeGonlv =  (addVoltage * addVoltage / this.powerData.resistanceValue);
              let baoliuyiweizhi = parseFloat(zuihoudeGonlv.toFixed(1));
              console.log(baoliuyiweizhi)
              console.log(this.powerData.maxPower)
              if(baoliuyiweizhi >= this.powerData.minPower) {
                //修改成功
                console.log('修改成功');
                this.$emit('changeCurrentData',{
                  index:index,
                  value:addVoltage
                })
                chartData[index] = baoliuyiweizhi;
                this.chartInstance.setOption({
                  series:[{
                    data: chartData,
                  }]
                })

                this.$emit('changeCharts',chartData)
              }
            }
          }
        // }
      }

    }
  }
}
</script>

<style scoped>

</style>